var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-good-table',_vm._g(_vm._b({scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [(_vm.isLoading && slot === 'table-row')?[_c('span',{key:slot},[_c('div',{style:(("width: " + ((_vm.rowLoadingStyleData[scope.column.field] &&
                                _vm.rowLoadingStyleData[scope.column.field]
                                    .width) ||
                            '100%') + "; height: " + ((_vm.rowLoadingStyleData[scope.column.field] &&
                                _vm.rowLoadingStyleData[scope.column.field]
                                    .height) ||
                            '100%') + " "))},[_c('div',{style:(("width: " + (Math.floor(Math.random() * 51) + 50) + "%;"))},[_c('b-skeleton')],1)])])]:_vm._t(slot,null,null,scope)]}}}),{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("common.tablePageShowing"))+" ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":[5, 10, 15]},on:{"input":_vm.emitPageSizeChangedEvent},model:{value:(_vm.$attrs.paginationOptions.perPage),callback:function ($$v) {_vm.$set(_vm.$attrs.paginationOptions, "perPage", $$v)},expression:"$attrs.paginationOptions.perPage"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("common.tablePageEntries", { quantity: _vm.totalRows, }))+" ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"perPage":_vm.$attrs.paginationOptions.perPage,"totalRows":_vm.totalRows,"value":1,"align":"right","firstNumber":"","lastNumber":"","nextClass":"next-item","prevClass":"prev-item"},on:{"input":function (value) { return props.pageChanged({
                                    currentPage: value,
                                }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}],null,true)},'vue-good-table',_vm.$attrs,false),_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }