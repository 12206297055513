/**
 * @typedef {Object} DatatableFilterSchema - Filter schema
 * @property {string} name - name of the filter attribute on json api
 * @property {string} [filterAttribute] - name of the attribute to be used on filter value (Eg. Resource name = emails filterAttribute = addresses. Result = emails.addresses)
 * @property {string} [label] - label of the filter
 * @property {string} [placeholder] - placeholder of the filter
 * @property {string} type - Filter Type @see TYPES
 * @property {ListOption} [options] - List of Options, Required when type = LIST. Represents the available options for the filter
 * @property {string|number|boolean|Array} [currentValue] - Current value of the filter
 */

/**
 * @typedef {Object} ListOption - List of options to be used with list filter
 * @property {string} label
 * @property {string|number} value
 */

/**
 * @typedef {Object} FilterOptions - Filter Schema to use with Datatable
 * @property {string} [label] - label of the filter
 * @property {string} [filterAttribute] - name of the attribute to be used on filter value
 * @property {string} [placeholder] - placeholder of the filter
 * @property {Object} ApiData - Data used on async requests of select components (vue-select-async)
 */

/**
 * Enum datatable filter types
 * @readonly
 * @enum {string}
 */
const TYPES = {
    TEXT: "text",
    LIST: "list",
    DATE: "date",
    DATE_RANGE: "date-range",
    DATETIME: "datetime",
    NUMERIC: "numeric",
    SELECT_ASYNC: "select-async",
}
/** @type {DatatableFilterSchema} */
const DATATABLE_FILTER_SCHEMA = {
    name: "",
    label: "",
    placeholder: "",
    type: "",
}

/**
 * @param name {string}
 * @param filterOptions {FilterOptions}
 * @returns {DatatableFilterSchema}
 */
function createTextFilter(name, filterOptions = {}) {
    return {
        ...DATATABLE_FILTER_SCHEMA,
        name,
        ...filterOptions,
        ...{ type: TYPES.TEXT },
    }
}

/**
 * @param name {string}
 * @param filterOptions {FilterOptions}
 * @returns {DatatableFilterSchema}
 */
function createDateFilter(name, filterOptions = {}) {
    return {
        ...DATATABLE_FILTER_SCHEMA,
        name,
        ...filterOptions,
        ...{ type: TYPES.DATE },
    }
}

/**
 * @param name {string}
 * @param filterOptions {FilterOptions}
 * @returns {DatatableFilterSchema}
 */
function createDateRangeFilter(name, filterOptions = {}) {
    return {
        ...DATATABLE_FILTER_SCHEMA,
        name,
        ...filterOptions,
        ...{ type: TYPES.DATE_RANGE },
    }
}

/**
 *
 * @param name
 * @param listOptions {ListOption[]}
 * @param filterOptions {FilterOptions}
 * @returns {DatatableFilterSchema}
 */
function createListFilter(name, listOptions, filterOptions = {}) {
    if (!listOptions || !listOptions.length) {
        throw new Error("List with invalid options")
    }
    // todo documentar o tipo do options (label e value)
    return {
        ...DATATABLE_FILTER_SCHEMA,
        name,
        options: listOptions,
        ...filterOptions,
        ...{ type: TYPES.LIST },
    }
}

/**
 * Creates a generic search filter with name = search
 * @returns {DatatableFilterSchema}
 */
function createSearchFilter() {
    return {
        ...DATATABLE_FILTER_SCHEMA,
        ...{ name: "search" },
        ...{ type: TYPES.TEXT },
    }
}

/**
 * @param name
 * @param filterOptions {FilterOptions}
 * @param apiData {ApiData}
 * @returns {DatatableFilterSchema}
 */
function createSelectAsyncFilter(name, filterOptions = {}, apiData = {}) {
    return {
        ...DATATABLE_FILTER_SCHEMA,
        name,
        ...filterOptions,
        ...apiData,
        ...{ type: TYPES.SELECT_ASYNC },
    }
}

/**
 * Builds filter request from datatable filters
 * @param filtersDefinitions
 * @returns {{}}
 */
function convertDatatableFilterToJsonApi(filtersDefinitions) {
    const formattedFilter = {}

    if (!filtersDefinitions || !Object.keys(filtersDefinitions).length) {
        return formattedFilter
    }

    // generic search
    if (filtersDefinitions.search) {
        formattedFilter.search = filtersDefinitions.search
    }

    Object.values(filtersDefinitions).forEach(filter => {
        if (
            !filter ||
            !filter.currentValue ||
            (Array.isArray(filter.currentValue) &&
                !filter.currentValue.length) ||
            !filter.type
        ) {
            return
        }

        const filterName = filter.name
        let filterValue = filter.currentValue

        const { filterAttribute } = filter

        if (filterAttribute) {
            filterValue = { [filterAttribute]: filterValue }
        }

        formattedFilter[filterName] = filterValue
    })

    return formattedFilter
}

export default {
    TYPES,
    serializer: {
        datatableFilterToJsonApi: convertDatatableFilterToJsonApi,
    },
    createFilter: {
        search: createSearchFilter,
        text: createTextFilter,
        list: createListFilter,
        searchAsync: createSelectAsyncFilter,
        date: createDateFilter,
        dateRange: createDateRangeFilter,
    },
    TYPEDEFS: {
        DATATABLE_FILTER_SCHEMA,
    },
}
