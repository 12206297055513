<template>
    <div>
        <!-- https://stackoverflow.com/questions/50891858/vue-how-to-pass-down-slots-inside-wrapper-component-->
        <vue-good-table v-bind="$attrs" v-on="$listeners">
            <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
                <template v-if="isLoading && slot === 'table-row'">
                    <span :key="slot">
                        <div
                            :style="`width: ${
                                (rowLoadingStyleData[scope.column.field] &&
                                    rowLoadingStyleData[scope.column.field]
                                        .width) ||
                                '100%'
                            }; height: ${
                                (rowLoadingStyleData[scope.column.field] &&
                                    rowLoadingStyleData[scope.column.field]
                                        .height) ||
                                '100%'
                            } `"
                        >
                            <div
                                :style="`width: ${
                                    Math.floor(Math.random() * 51) + 50
                                }%;`"
                            >
                                <b-skeleton></b-skeleton>
                            </div>
                        </div>
                    </span>
                </template>
                <slot v-else :name="slot" v-bind="scope" />
            </template>

            <template #pagination-bottom="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap">
                            {{ $t("common.tablePageShowing") }}
                        </span>
                        <b-form-select
                            v-model="$attrs.paginationOptions.perPage"
                            :options="[5, 10, 15]"
                            class="mx-1"
                            @input="emitPageSizeChangedEvent"
                        />
                        <span class="text-nowrap">
                            {{
                                $t("common.tablePageEntries", {
                                    quantity: totalRows,
                                })
                            }}
                        </span>
                    </div>
                    <div>
                        <b-pagination
                            :perPage="$attrs.paginationOptions.perPage"
                            :totalRows="totalRows"
                            :value="1"
                            align="right"
                            class="mt-1 mb-0"
                            firstNumber
                            lastNumber
                            nextClass="next-item"
                            prevClass="prev-item"
                            @input="
                                (value) =>
                                    props.pageChanged({
                                        currentPage: value,
                                    })
                            "
                        >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table"
import { BFormSelect, BPagination, BSkeleton } from "bootstrap-vue"
import _ from "lodash"

export default {
    name: "Datatable",
    components: {
        VueGoodTable,
        BFormSelect,
        BPagination,
        BSkeleton,
    },
    inheritAttrs: false,
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        totalRows: {
            type: Number,
            default: 0,
        },
    },
    emits: ["onPageSizeChange"],
    data() {
        return {
            rowLoadingStyleData: {},
        }
    },
    updated() {
        this.$nextTick(() => {
            this.calculateColumnsSizes()
        })
    },
    methods: {
        /**
         * Calculate the size of the columns for better UX on skeleton loading state
         */
        calculateColumnsSizes() {
            const columns = _.cloneDeep(this.$attrs.columns)
            const tdElements = this.$el.getElementsByTagName("tr")[1].children
            if (tdElements && tdElements.length > 1) {
                this.rowLoadingStyleData = tdElements.forEach(
                    (element, index) => {
                        const computedStyle = getComputedStyle(element)

                        let elementHeight = element.clientHeight // height with padding
                        let elementWidth = element.clientWidth // width with padding

                        elementHeight -=
                            parseFloat(computedStyle.paddingTop) +
                            parseFloat(computedStyle.paddingBottom)
                        elementWidth -=
                            parseFloat(computedStyle.paddingLeft) +
                            parseFloat(computedStyle.paddingRight)
                        columns[index].width = `${elementWidth}px`
                        columns[index].height = `${elementHeight}px`
                    }
                )

                this.rowLoadingStyleData = columns.reduce((acc, column) => {
                    acc[column.field] = {
                        width: column.width,
                        height: column.height,
                    }
                    return acc
                }, {})
            }
        },
        emitPageSizeChangedEvent(pageSize) {
            // todo testar o método posteriormente, está bem lento
            this.$emit("onPageSizeChange", pageSize)
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.skeletonTable {
    position: relative;
    top: -50px;
    margin: 0px 20px;
}

.vgt-inner-wrap.is-loading {
    opacity: 1;
}
</style>
